import { FC, useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import smoothscroll from 'smoothscroll-polyfill'
import { useInView } from 'react-intersection-observer'

import { styled } from '@liftfoils/styles'
import { SectionVideoPlaylistProps } from '@liftfoils/models'

import {
  Container,
  Button,
  YouTubePlayer,
  PortableText,
  VideoModalContentWrap,
  Gradient,
  VideoCard,
  Modal,
  Media,
} from '@liftfoils/components'
import { getYouTubeIdFromUrl } from '@liftfoils/utils'
import { ArrowLeft, ArrowRight, PlayIcon } from '@liftfoils/icons'

const COLUMNGAP = 8

const PlaylistNav = styled('div', {
  lift_font: 'heading02',
  display: 'grid',
  justifyContent: 'end',
  gridTemplateColumns: 'min-content min-content',
  color: 'white',
  columnGap: COLUMNGAP + 'px',
  variants: {
    hidden: {
      true: {
        visibility: 'hidden',
      },
    },
  },
})

const VideoWrapper = styled('div', {
  position: 'relative',
  display: 'grid',
  cursor: 'pointer',
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  height: '70vh',
  minHeight: '500px',
  '@lg': {
    minHeight: '40vw',
  },
})

const Content = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  zIndex: 1,
  display: 'grid',
  height: '100%',
})

const TopWrap = styled('div', {
  $hasContainerMX: true,
  gridRow: 1,
  gridColumn: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  pt: '$8',
  '@md': {
    pt: '$10',
  },
})

const Heading = styled('h2', {
  lift_font: 'heading02',
  color: '$white_06',
  '@md': {
    maxWidth: '50%',
  },
  '.highlighted': {
    color: 'white',
  },
})

const LinkWrap = styled('div', {
  display: 'grid',
  alignContent: 'right',
  justifyItems: 'right',
  alignSelf: 'right',
  pb: '$10',
})

const PlaylistHeadingWrapper = styled('div', {
  position: 'relative',
  background: 'linear-gradient(0deg, #2E3335 10%, rgba(46, 51, 53, 0) 100%)',
  display: 'grid',
  marginTop: '-200px',
  pb: '100px',
  zIndex: 9,
  pt: '$10',
})

const PlaylistWrapper = styled('div', {
  backgroundColor: '$navy800',
  display: 'grid',
  position: 'relative',
  mt: '-1px',
})

const PlaylistHead = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridGap: '$4',
  mb: '$8',
  alignItems: 'center',
})

const PlaylistHeading = styled('h3', {
  lift_font: 'heading02',
  color: 'white',
})

const PlaylistSlider = styled('div', {
  display: 'flex',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollPaddingLeft: '$containerMargin',
  scrollbarWidth: 'none',
  overflowY: 'hidden',
  pb: '$8',
  marginTop: '-100px',
  zIndex: 9,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const CardsWrap = styled('div', {
  display: 'grid',
  gridGap: '$5',
  gridAutoFlow: 'column',
  gridAutoColumns: '200px',
  '@lg': {
    gridAutoColumns: '300px',
  },
})
const Buffer = styled('div', {
  display: 'block',
  minWidth: '5vw',
  marginLeft: '$0',
})

export const SectionVideoPlaylist: FC<SectionVideoPlaylistProps> = ({
  title,
  buttonLabel,
  videoUrl,
  background,
  videosHeading,
  videos,
  priority,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const [isScrolled, setScrolled] = useState(false)
  const [isMainVideoModalOpen, setMainVideoModalOpen] = useState(false)
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)

  const [state, setState] = useState<{
    slider: HTMLDivElement | null
    itemWidth?: number
  }>({
    slider: null,
    itemWidth: 0,
  })

  const stopScrollAnimation = useCallback(
    debounce(() => setScrolled(false), 1200),
    [],
  )

  const scrollBySlides = (numberOfSlides: number) => {
    if (state.itemWidth) {
      setScrolled(true)
      stopScrollAnimation()
      state.slider?.scrollBy({
        left: numberOfSlides * (state.itemWidth + COLUMNGAP),
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const [refBufferLeft, isBufferLeftInView] = useInView({
    root: sliderRef.current,
    threshold: 0.92,
  })

  const [refBufferRight, isBufferRightInView] = useInView({
    root: sliderRef.current,
    threshold: 0.92,
  })

  useEffect(() => {
    smoothscroll.polyfill()
    const slider = sliderRef.current
    const itemWidth = slider?.children[1].clientWidth

    setState({ ...state, slider, itemWidth })
  }, [])

  const areAllElementsInView = isBufferLeftInView && isBufferRightInView

  return (
    <>
      <Modal
        isOpen={isMainVideoModalOpen}
        onRequestClose={() => {
          setMainVideoModalOpen(false)
          setVideoModalOpen(false)
        }}
        overlayCloseButton
        variant={'rounded'}
        overlayVariant="blur"
      >
        <VideoModalContentWrap>
          <YouTubePlayer videoId={getYouTubeIdFromUrl(videoUrl?.url)} />
        </VideoModalContentWrap>
      </Modal>

      <VideoWrapper
        onClick={() => {
          setMainVideoModalOpen(!isMainVideoModalOpen)
          setVideoModalOpen(!isMainVideoModalOpen)
        }}
      >
        {background && (
          <MediaWrap>
            <Media
              {...background}
              layout={'fill'}
              isVideoPaused={isVideoModalOpen}
              priority={priority}
              sizes="100vw"
              alt={background?.alt ?? ''}
            />
            <Gradient from={'top'} css={{ height: '35%' }} />
          </MediaWrap>
        )}
        <Content>
          <TopWrap>
            {title && (
              <Heading>
                <PortableText value={title} />
              </Heading>
            )}
            {buttonLabel && (
              <LinkWrap>
                <Button
                  size={'medium'}
                  appearance={'blur'}
                  onClick={() => {
                    setMainVideoModalOpen(!isMainVideoModalOpen)
                    setVideoModalOpen(!isMainVideoModalOpen)
                  }}
                >
                  <PlayIcon />
                  {buttonLabel}
                </Button>
              </LinkWrap>
            )}
          </TopWrap>
        </Content>
      </VideoWrapper>
      <PlaylistHeadingWrapper>
        <Container>
          <PlaylistHead>
            {videosHeading && (
              <PlaylistHeading>{videosHeading}</PlaylistHeading>
            )}
            <PlaylistNav hidden={areAllElementsInView}>
              <Button
                disabled={isBufferLeftInView}
                appearance={'blur'}
                size={'small'}
                onClick={() => scrollBySlides(-1)}
              >
                <ArrowLeft />
              </Button>
              <Button
                disabled={isBufferRightInView}
                appearance={'blur'}
                size={'small'}
                onClick={() => scrollBySlides(1)}
              >
                <ArrowRight />
              </Button>
            </PlaylistNav>
          </PlaylistHead>
        </Container>
      </PlaylistHeadingWrapper>
      <PlaylistWrapper>
        <PlaylistSlider ref={sliderRef}>
          <Buffer ref={refBufferLeft} />
          <CardsWrap>
            {videos?.map((video, index) => {
              if (!video) return null
              return (
                <VideoCard
                  key={`video-card-${index}`}
                  card={{
                    _key: index.toString(),
                    video: {
                      url: video.videoUrl?.url,
                      _type: video.videoUrl?._type,
                    },
                    richTitle: video.title,
                    thumbnail: video.thumbnail,
                  }}
                  onRequestOpen={() => {
                    setVideoModalOpen(true)
                  }}
                  onRequestClose={() => {
                    setVideoModalOpen(false)
                  }}
                  enableSnap={!isScrolled}
                />
              )
            })}
          </CardsWrap>
          <Buffer ref={refBufferRight} />
        </PlaylistSlider>
      </PlaylistWrapper>
    </>
  )
}
